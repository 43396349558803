import React, { useContext, useEffect, Suspense } from "react";
import { AppProvider, Frame } from "@shopify/polaris";
import Login from "./Components/Auth/Login";
import jwt_decode from "jwt-decode";
import setAuthToken from "./Components/Utils/setAuthToken";
import { AuthContext } from "./Store/contexts/authContext";
import { setCurrentUser } from "./Store/actions/authActions";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "./axios";
import "antd/dist/antd.css";
import Spinner from "./Components/UI/Spinner";
import { lazyWithRetry } from "./Components/Utils/lazyWithRetry";

//customer flow
const Search = lazyWithRetry(() =>
  import("./Components/NewCustomerFlow/Search")
);
const OrderList = lazyWithRetry(() =>
  import("./Components/NewCustomerFlow/OrderList")
);
const Return = lazyWithRetry(() =>
  import("./Components/NewCustomerFlow/Return/Return")
);
const Exchange = lazyWithRetry(() =>
  import("./Components/NewCustomerFlow/Exchange/Exchange")
);
const Details = lazyWithRetry(() =>
  import("./Components/NewCustomerFlow/Details")
);
//admin
const Home = lazyWithRetry(() => import("./Components/Admin/Home"));
const NotFound = lazyWithRetry(() => import("./Components/UI/NotFound"));

const App = () => {
  const { dispatch } = useContext(AuthContext);
  useEffect(() => {
    //check for token
    if (localStorage.getItem("RP_token")) {
      // console.log("app.js token exist");
      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("RP_token");
      setAuthToken(localStorage.getItem("RP_token"));
      const decoded = jwt_decode(localStorage.getItem("RP_token"));
      // console.log("decoded", decoded);
      localStorage.setItem("RP_user_id", decoded.id);
      localStorage.setItem("RP_store_url", decoded.store);
      localStorage.setItem("RP_store_email", decoded.email);
      dispatch(setCurrentUser(decoded));
    }
  }, [dispatch]);

  return (
    <AppProvider>
      <Frame>
        <div className="container">
          <Router>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route exact path="/user/login" component={Login} />

                {/* Private Routes */}

                <Route path="/admin" component={Home} />

                {/* Customer Routes */}

                <Route exact path="/:url" component={Search} />
                {/* <Route
                  exact
                  path="/:url/:order_number/:email"
                  component={OrderList}
                /> */}
                <Route exact path="/orderlist/:token" component={OrderList} />

                <Route
                  exact
                  path="/:store/exchange/:order_id/:lineitem_id/:session_id"
                  component={Exchange}
                />
                <Route
                  exact
                  path="/:store/return/:order_id/:lineitem_id/:session_id"
                  component={Return}
                />
                <Route
                  exact
                  path="/details/:store/:request_id"
                  component={Details}
                />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      </Frame>
    </AppProvider>
  );
};

export default App;
