import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@shopify/polaris/styles.css";
import "antd/dist/antd.css";

import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./Store/contexts/authContext";
import mixpanel from "mixpanel-browser";

//Sentry error tracking
import * as Sentry from "@sentry/react";

// import { debugContextDevtool } from "react-context-devtool";

const container = document.getElementById("root");
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}
//Sentry integration
// Sentry.init({
//   dsn: "https://9f1430889bb946b2904f30ae77c0ed15@o921583.ingest.sentry.io/5868108",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
//mixpanel.init("07dbb3be67b77ace43eaed584e41c1a1");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://34667e0cb6b3419eabb09ce982ec196c@o921583.ingest.sentry.io/4505412922114048",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const methodDoesNotExist = () => {
  this.Replay();
};

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  container
);
// debugContextDevtool(container, {
//   disable: process.env.NODE_ENV === "production",
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
