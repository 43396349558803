//Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_STORE_LOGO = "SET_STORE_LOGO";
export const GET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING = "LOADING";
export const REQUEST_LOADING = "REQUEST_LOADING";
export const IS_FETCHED = "IS_FETCHED";
export const STOP_LOADING = "STOP_LOADING";
export const OPEN_CHAT = "OPEN_CHAT";
export const START_LOADING = "START_LOADING";
export const FETCHING = "FETCHING";
export const TIMELINE_FETCHING_START = "TIMELINE_FETCHING_START";
export const TIMELINE_FETCHING_SUCCESS = "TIMELINE_FETCHING_SUCCESS";
export const TIMELINE_FETCHING_ERROR = "TIMELINE_FETCHING_ERROR";
export const COMMENT_SUBMITTING = "COMMENT_SUBMITTING";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const UPDATE_STORE_SETUP = "UPDATE_STORE_SETUP";
export const REMOVE_KEEP_SHOWING_DASHBOARD = "REMOVE_KEEP_SHOWING_DASHBOARD";
export const GET_STORE_LOCATIONS = "GET_STORE_LOCATIONS";
//GET_WAREHOUSE_LOCATIONS
export const GET_WAREHOUSE_LOCATIONS = "GET_WAREHOUSE_LOCATIONS";
export const WAREHOUSE_LOCATIONS_FETCHING_START =
  "WAREHOUSE_LOCATIONS_FETCHING_START";
export const WAREHOUSE_LOCATIONS_FETCHING_SUCCESS =
  "WAREHOUSE_LOCATIONS_FETCHING_SUCCESS";
export const WAREHOUSE_LOCATIONS_FETCHING_ERROR =
  "WAREHOUSE_LOCATIONS_FETCHING_ERROR";

//GET_LOGISTICS
export const LOGISTICS_FETCHING_START = "LOGISTICS_FETCHING_START";
export const LOGISTICS_FETCHING_SUCCESS = "LOGISTICS_FETCHING_SUCCESS";
export const LOGISTICS_FETCHING_ERROR = "LOGISTICS_FETCHING_ERROR";

//GET_INTEGRATION
export const INTEGRATION_FETCHING_START = "INTEGRATION_FETCHING_START";
export const INTEGRATION_FETCHING_SUCCESS = "INTEGRATION_FETCHING_SUCCESS";
export const INTEGRATION_FETCHING_ERROR = "INTEGRATION_FETCHING_ERROR";

//Returns and exchanges
export const RESET_RETURN_DATA = "RESET_RETURN_DATA";
export const GET_DATA = "GET_DATA";
export const GET_TIMELINE_DATA = "GET_TIMELINE_DATA";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

//GET NEXT PREV REQUEST
export const GET_NEXT_PREV_REQUEST = "GET_NEXT_PREV_REQUEST";
export const GET_NEXT_PREV_REQUEST_FETCHEING =
  "GET_NEXT_PREV_REQUEST_FETCHEING";
export const GET_NEXT_PREV_REQUEST_ERROR = "GET_NEXT_PREV_REQUEST_ERROR";

//FETCH STORE SETTINGS
export const FETCH_STORE_SETTINGS = "FETCH_STORE_SETTINGS";

//Return & Exchange Details

export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const SHOW_USPSMODAL = "SHOW_USPSMODAL";
export const CLOSE_SINGLE_MODAL = "CLOSE_SINGLE_MODAL";

//weight
export const OPEN_UPDATE_WEIGHT_MODAL = "OPEN_UPDATE_WEIGHT_MODAL";
export const OPEN_GET_PRICING_MODAL = "OPEN_GET_PRICING_MODAL";
export const UPDATE_WEIGHT_DATA = "UPDATE_WEIGHT_DATA";
export const UPDATE_PRICING_DATA = "UPDATE_PRICING_DATA";

export const APPROVE_ORDER = "APPROVE_ORDER";
export const APPROVE_UPS_ORDER = "APPROVE_UPS_ORDER";
export const APPROVE_DPDHL_ORDER = "APPROVE_DPDHL_ORDER";
export const APPROVE_SHIPSTATION_ORDER = "APPROVE_SHIPSTATION_ORDER";
export const APPROVE_CANADAPOST_ORDER = "APPROVE_CANADAPOST_ORDER";
export const APPROVE_SHIPPO_ORDER = "APPROVE_SHIPPO_ORDER";
export const APPROVE_EASYPARCEL_ORDER = "APPROVE_EASYPARCEL_ORDER";
export const APPROVE_FEDEX_ORDER = "APPROVE_FEDEX_ORDER";

export const UPDATE_STATE = "UPDATE_STATE";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const RESET_REQUEST_DATA = "RESET_REQUEST_DATA";

//Reship request
export const RESHIP_REQUEST = "RESHIP_REQUEST";

//timeline
export const ENABLE_AND_RESEND = "ENABLE_AND_RESEND";
export const GET_TRACKING_STATUS = "GET_TRACKING_STATUS";
export const POST_COMMENT = "POST_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

//Toast
export const DISMISS_BANNER = "DISMISS_BANNER";
export const SHOW_TOAST = "SHOW_TOAST";
export const DISMISS_TOAST = "DISMISS_TOAST";

//Error Modal
export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";

//CUSTOMERS
export const SET_SETTINGS = "SET_SETTINGS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const ADD_ITEM = "ADD_ITEM";

//ui
export const TOGGLE_HOTKEY_DIALOG = "TOGGLE_HOTKEY_DIALOG";
