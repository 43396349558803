import {
  SET_CURRENT_USER,
  SET_STORE_LOGO,
  LOADING,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_DASHBOARD_DATA,
  SHOW_TOAST,
  DISMISS_TOAST,
  UPDATE_STORE_SETUP,
  REMOVE_KEEP_SHOWING_DASHBOARD,
  TOGGLE_HOTKEY_DIALOG,
} from "./types";
import axios from "../../axios";
import setAuthToken from "../../Components/Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { timeoutMessage } from "./../../Components/Utils/constants";

//Register User
export const registerUser = (newUserData, history, dispatch) => {
  dispatch({ type: LOADING });
  axios
    .post("/user/signup", newUserData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("RP_token", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      if (err && err.code === "ECONNABORTED") {
        let payload = {
          toastMsg: timeoutMessage,
          toastError: true,
        };
        dispatch({
          type: SHOW_TOAST,
          payload: payload,
        });
      }
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

//Login - Get User Token
export const loginUser = (userData, dispatch, history) => {
  dispatch({ type: LOADING });
  axios
    .post("/user/login", userData)
    .then((res) => {
      // Save to localStorage
      //console.log(res);
      const { token, refresh_token } = res.data;
      // Set token to ls
      localStorage.setItem("RP_token", token);
      localStorage.setItem("RP_refresh_token", refresh_token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      localStorage.setItem("RP_user_id", decoded.id);
      localStorage.setItem("RP_store_url", decoded.store);
      localStorage.setItem("RP_store_email", decoded.email);
      // Set current user
      dispatch(setCurrentUser(decoded));
      //clean errors
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      // console.log(err);
      if (err && err.code === "ECONNABORTED") {
        let payload = {
          toastMsg: timeoutMessage,
          toastError: true,
        };
        dispatch({
          type: SHOW_TOAST,
          payload: payload,
        });
      }
      //console.log(err.response);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Log user out
export const logoutUser = (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("RP_token");
  localStorage.removeItem("RP_refresh_token");

  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  //console.log("decoded", decoded);
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//set store logo
export const setStoreLogo = (dispatch) => {
  axios.get("/return/dashboard_data").then((res) => {
    let data = res.data;
    if (data.success) {
      localStorage.setItem("RP_store_logo", data.data.store_logo);
      dispatch({
        type: SET_STORE_LOGO,
        payload: data.data.store_logo,
      });
    }
    //console.log(data);
  });
};

//Get Dashboard

export const getDashboardData = (dispatch) => {
  let url = "/dashboard/v1";
  dispatch({ type: LOADING });
  axios
    .get(url)
    .then((res) => {
      const data = res.data.data;
      //console.log("data", data);
      localStorage.setItem("RP_store_logo", data.store_logo);
      dispatch({
        type: GET_DASHBOARD_DATA,
        payload: data,
      });

      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      if (err && err.code === "ECONNABORTED") {
        let payload = {
          toastMsg: timeoutMessage,
          toastError: true,
        };
        dispatch({
          type: SHOW_TOAST,
          payload: payload,
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const toggleToast = (dispatch) => {
  dispatch({
    type: DISMISS_TOAST,
  });
};

//in payload pass tostmsg & tostErr
export const showToastMsg = (dispatch, payload) => {
  dispatch({
    type: SHOW_TOAST,
    payload: payload,
  });
};

export const updateDashboardSetup = (dispatch, payload) => {
  dispatch({
    type: UPDATE_STORE_SETUP,
    payload: payload,
  });
};

export const removeKeepShowingDashboardSteps = (dispatch) => {
  dispatch({
    type: REMOVE_KEEP_SHOWING_DASHBOARD,
  });
};

export const toggleHotKeysDialog = (dispatch) => {
  dispatch({
    type: TOGGLE_HOTKEY_DIALOG,
  });
};
