import React, { Fragment } from "react";
import { Spinner } from "@shopify/polaris";
import "./spinner.scss";

const spinner = ({ fixed, block, infinite, size = "large" }) => {
  return (
    <Fragment>
      <div
        className={`spinner ${fixed ? "spinner__fixed" : ""}${
          block ? "spinner__block" : ""
        } ${infinite ? "spinner__infinite" : ""}`}
      >
        <Spinner size={size} color="teal" />
      </div>
    </Fragment>
  );
};

export default spinner;
