import React, { useContext, useState, useEffect, Fragment } from "react";
import "./style.scss";
import tick from "../../assets/tick.png";
import emailicon from "../../assets/email-icon.png";
import passwordicon from "../../assets/pass-icon.png";
import storeicon from "../../assets/store-icon.png";
import { InlineError, Banner } from "@shopify/polaris";

//import context Object to consume
import { AuthContext } from "../../Store/contexts/authContext";
//import actions
import { loginUser } from "../../Store/actions/authActions";
import axios from "../../axios";
import { isEmail, isRequired } from "./../Utils/Validation";
import { isMobileOnly } from "react-device-detect";
import MobileRestrict from "./../UI/MobileRestrict";
import Spinner from "../UI/Spinner";
import { removeHttp } from "../Utils/Utils";

const Login = (props) => {
  const { state, dispatch } = useContext(AuthContext);
  //console.log(state);

  const { errors, isAuthenticated } = state;

  const userData = {
    email: "",
    password: "",
    store: "",
    sessionExpired: false,
    notFound: false,
  };

  const [isStoreNull, setIsStoreNull] = useState(false);

  const [user, setUser] = useState(userData);
  const [error, setErrors] = useState(errors);
  const [loading, setLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  //Desctruction
  const { email, password, store, sessionExpired, notFound } = user;

  //use Effect
  const query = new URLSearchParams(props.location.search);

  useEffect(() => {
    const store_url = query.get("store");
    const session_expired = query.get("session");
    const notFoundErrorMessage = query.get("notfound");

    console.log("storeurl", store_url);
    let storeUrl = null;
    if (store_url) {
      storeUrl = store_url;
    } else if (localStorage.RP_store_url) {
      storeUrl = localStorage.getItem("RP_store_url");
    }

    if (store_url && store_url.includes("myshopify.com")) {
      setUser({
        ...userData,
        store: storeUrl,
        sessionExpired: session_expired,
        notFound: notFoundErrorMessage,
      });
    } else {
      setUser({
        ...userData,
        store: "",
        sessionExpired: session_expired,
        notFound: notFoundErrorMessage,
      });
      setIsStoreNull(true);
    }

    setIsFetched(true);
  }, []);
  //it will check for errors, if errors then will uppdate the state
  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  //If User is login then rediret to dashboard
  useEffect(() => {
    //console.log("login", state, isAuthenticated, props.location.state);
    var referer = props.location.state
      ? `${props.location.state.from.pathname}${props.location.state.from.search}`
      : "/admin/dashboard";
    //console.log("referer", props.location);

    if (isAuthenticated) {
      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("RP_token");
      props.history.push(referer);
    }
  }, [isAuthenticated, props.history]);

  //handelChange
  const handelChange = (e) => {
    setErrors(null);
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      sessionExpired: false,
      notFound: false,
    });
  };

  //validations
  const handleValidation = () => {
    let err = {};
    let isValid = true;
    // if (!isEmail(email)) {
    //   isValid = false;
    //   err.email = "Please enter a valid email id";
    // }

    if (!isRequired(email)) {
      isValid = false;
      err.email = "Please enter a valid email id";
    }
    if (!isRequired(password)) {
      isValid = false;
      err.password = "Please enter your password";
    }
    if (isStoreNull && !isRequired(store)) {
      isValid = false;
      err.store = "Please enter your store url";
    }
    setErrors(err);
    return isValid;
  };
  //handelclick
  const handelSubmit = (e) => {
    let sendObj = {
      email: email,
      password: password,
      store: store.trim(),
    };

    if (isStoreNull) {
      if (store.includes(".")) {
        sendObj.store = `${store.substring(
          0,
          store.indexOf(".")
        )}.myshopify.com`;
      } else {
        sendObj.store = `${store}.myshopify.com`;
      }
    }
    sendObj.store = removeHttp(store).trim();

    e.preventDefault();
    if (handleValidation()) {
      //console.log(sendObj);
      loginUser(sendObj, dispatch, props.history);
      localStorage.setItem("RP_store_url", sendObj.store);
    }
  };
  if (isMobileOnly) {
    return <MobileRestrict />;
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      {loading && <Spinner />}
      {isFetched && (
        <div className="main-section">
          <div className="main-form-content">
            <div className="main-form-row">
              <div className="main-form-left-content">
                <h2 className="heading">Welcome to Return Prime</h2>
                <p>
                  Empowering eCommerce with one stop platform to track and
                  optimize returns and exchanges with data driven powerful
                  insight and seamless integrations.
                </p>
                <ul className="login-list-content">
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" />{" "}
                    Optimising Return/ Exchange Processing.
                  </li>
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" /> Seamless
                    Integrations
                  </li>
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" /> DIY
                    Returns and Exchange Policy configuration
                  </li>
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" /> Multiple
                    warehouse addition.
                  </li>
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" /> Live
                    analytics and powerful insights.
                  </li>
                  <li className="">
                    <img src={tick} alt="tick" className="tick-img" /> Efficient
                    process automation.
                  </li>
                </ul>
              </div>
              <div className="main-form-right-content">
                <div className="main-form-card">
                  <img
                    src="https://return-public-app.s3.ap-south-1.amazonaws.com/production_assets/black-sidebyside.png"
                    alt="logo"
                    className="logo-form"
                  />
                  {error && (
                    <InlineError message={error.message} fieldID="myFieldID" />
                  )}

                  {sessionExpired && (
                    <InlineError
                      message="Your session is expired, please login again."
                      fieldID="myFieldId2"
                    />
                  )}

                  {notFound && (
                    <Banner status="critical">
                      <p style={{ fontSize: "14px" }}>
                        You have visited an incorrect page it seems. You can
                        login from your Shopify Apps section directly or enter
                        your credentials below to access your Return Prime
                        dashboard.
                      </p>
                    </Banner>
                  )}

                  <form
                    className="form-signin"
                    onSubmit={handelSubmit}
                    noValidate
                  >
                    <div className="form-group">
                      {isStoreNull && (
                        <Fragment>
                          <div className="form-input-box with-icon with-suffix">
                            <input
                              type="text"
                              name="store"
                              className={`form-control ${
                                error && error.store && "error__field"
                              }`}
                              placeholder="Store URL"
                              required
                              value={user.store}
                              onChange={handelChange}
                            />

                            <img
                              src={storeicon}
                              className="login-form-icon"
                              alt="store-icon"
                            />
                            <span className="input__suffix">
                              .myshopify.com
                            </span>
                          </div>

                          {error && error.store && (
                            <InlineError message={error.store} />
                          )}
                        </Fragment>
                      )}

                      <div className="form-input-box with-icon">
                        <input
                          type="email"
                          name="email"
                          className={`form-control ${
                            error && error.email && "error__field"
                          }`}
                          placeholder="Email address"
                          required
                          value={user.email}
                          onChange={handelChange}
                        />
                        <img
                          src={emailicon}
                          className="login-form-icon"
                          alt="email-icon"
                        />
                      </div>
                      {error && error.email && (
                        <InlineError message={error.email} />
                      )}
                      <div className="form-input-box with-icon">
                        <input
                          type="password"
                          name="password"
                          className={`form-control ${
                            error && error.password && "error__field"
                          }`}
                          placeholder="Password"
                          required
                          value={user.password}
                          onChange={handelChange}
                        />
                        <img
                          src={passwordicon}
                          className="login-form-icon"
                          alt="password-icon"
                        />
                      </div>
                      {error && error.password && (
                        <InlineError message={error.password} />
                      )}
                      {/* <div className="forgot-password">
                      <a href="/">Forget Password?</a>
                    </div> */}
                    </div>
                    <div className="form-group">
                      <button className="login-btn" type="submit">
                        Login
                      </button>
                    </div>
                  </form>
                  {/* <div className="account">
                  <p>
                    or <Link to={`/user/forgot-password`}>Forgot password</Link>
                  </p>
                </div> */}
                  {/* <div className="account">
                      <p>
                        Don't have account?{" "}
                        <a href={`/user/signup?store=${user.store}`}>
                          {" "}
                          Sign Up
                        </a>
                        <Link to={`/user/signup?store=${user.store}`}>Sign Up</Link>
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
