//const emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,254}$/;
const emailRegx = /^[a-zA-Z0-9._+\-\']+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,254}$/;
let nameRegx = /^[A-Za-z ]+$/;
let acRegs = /^[0-9]{7,20}$/;
let ifscRegx = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
//let phoneRegx = /^\d{11}$/;
let phoneRegx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
let htmlRegx = /<\/?[a-z][\s\S]*>/;
let addressRegx = /^[\w\s.-]+\d+,\s*[\w\s.-]+$/;
let priceRegx = /^\d+(?:[.,]\d+)*$/;
let sarchPhoneRegx = /^[+-\w\s]{4,}$/;
let checkSpecialCharEndOfString = /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/;

export const isEmail = (email) => {
  let isValid = true;
  if (!emailRegx.test(email)) {
    isValid = false;
  }
  return isValid;
};

//Check if the input field is empty or not
export const isRequired = (fieldName) => {
  let regx = /\S/;
  let isValid = true;
  if (!regx.test(fieldName)) {
    isValid = false;
  }
  return isValid;
};

//check for ac noumber
export const isAccountNumber = (ac) => {
  let isValid = true;
  if (!acRegs.test(ac)) {
    isValid = false;
  }
  return isValid;
};

export const isConfirmAc = (ac, conf_ac) => {
  let isValid = true;
  if (ac !== conf_ac) {
    isValid = false;
  }
  return isValid;
};

//check for name
export const isName = (name) => {
  let isValid = true;
  if (!nameRegx.test(name)) {
    isValid = false;
  }
  return isValid;
};

//check for address
export const isAddress = (address) => {
  let isValid = true;
  if (address && address.trim().length < 1) {
    isValid = false;
  } else if (!address) {
    isValid = false;
  }
  return isValid;
};

//check for ifsc code
export const isIFSC = (ifsc) => {
  let isValid = true;
  if (!ifscRegx.test(ifsc)) {
    isValid = false;
  }
  return isValid;
};

//check for ifsc code
export const isPhone = (phone) => {
  let isValid = true;
  if (!phoneRegx.test(phone)) {
    isValid = false;
  }
  return isValid;
};

//check for phone or zipcode code
export const isPhoneOrZip = (phone) => {
  let isValid = true;
  if (!sarchPhoneRegx.test(phone)) {
    isValid = false;
  }
  return isValid;
};

//check if string contains html
export const isHtml = (string) => {
  let isValid = true;
  if (!htmlRegx.test(string)) {
    isValid = false;
  }
  return isValid;
};

//check the input is email or phone
export const validateEmailPhoneInput = (field) => {
  let email = false;
  if (emailRegx.test(field)) {
    email = true;
  } else if (phoneRegx.test(field)) {
    email = false;
  }

  return email;
};

//check the string is number
export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

//check if the string contains any special character
export const checkForSpecialChar = (str) => {
  let format = /[ `!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(str);
};

export const checkIfSpecialCharEndOfString = (str) => {
  return checkSpecialCharEndOfString.test(str);
};

//check for price validation
export const isPrice = (price) => {
  let isValid = true;
  if (!priceRegx.test(price)) {
    isValid = false;
  }
  return isValid;
};

//Restrict input 2 decimal places
export const restrictToDecimal = (value) => {
  var t = value;
  t =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
      : t;

  return t;
};
