import { isHtml } from "./Validation";
import Placeholder from "../../assets/placeholder.png";

/* eslint-disable no-redeclare */
/* eslint-disable no-array-constructor */
// export function toWords(amount) {
//   console.log("number", amount);
//   var words = new Array();
//   words[0] = "";
//   words[1] = "One";
//   words[2] = "Two";
//   words[3] = "Three";
//   words[4] = "Four";
//   words[5] = "Five";
//   words[6] = "Six";
//   words[7] = "Seven";
//   words[8] = "Eight";
//   words[9] = "Nine";
//   words[10] = "Ten";
//   words[11] = "Eleven";
//   words[12] = "Twelve";
//   words[13] = "Thirteen";
//   words[14] = "Fourteen";
//   words[15] = "Fifteen";
//   words[16] = "Sixteen";
//   words[17] = "Seventeen";
//   words[18] = "Eighteen";
//   words[19] = "Nineteen";
//   words[20] = "Twenty";
//   words[30] = "Thirty";
//   words[40] = "Forty";
//   words[50] = "Fifty";
//   words[60] = "Sixty";
//   words[70] = "Seventy";
//   words[80] = "Eighty";
//   words[90] = "Ninety";
//   amount = amount.toString();
//   var atemp = amount.split(".");
//   var number = atemp[0].split(",").join("");
//   var n_length = number.length;
//   var words_string = "";
//   if (n_length <= 9) {
//     var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
//     var received_n_array = new Array();
//     for (var i = 0; i < n_length; i++) {
//       received_n_array[i] = number.substr(i, 1);
//     }
//     for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
//       n_array[i] = received_n_array[j];
//     }
//     for (var i = 0, j = 1; i < 9; i++, j++) {
//       if (i === 0 || i === 2 || i === 4 || i === 7) {
//         if (n_array[i] === 1) {
//           n_array[j] = 10 + parseInt(n_array[j]);
//           n_array[i] = 0;
//         }
//       }
//     }
//     let value = "";
//     for (var i = 0; i < 9; i++) {
//       if (i === 0 || i === 2 || i === 4 || i === 7) {
//         value = n_array[i] * 10;
//       } else {
//         value = n_array[i];
//       }
//       if (value !== 0) {
//         words_string += words[value] + " ";
//       }
//       if (
//         (i === 1 && value !== 0) ||
//         (i === 0 && value !== -0 && n_array[i + 1] === 0)
//       ) {
//         words_string += "Crores ";
//       }
//       if (
//         (i === 3 && value !== 0) ||
//         (i === 2 && value !== 0 && n_array[i + 1] === 0)
//       ) {
//         words_string += "Lakhs ";
//       }
//       if (
//         (i === 5 && value !== 0) ||
//         (i === 4 && value !== 0 && n_array[i + 1] === 0)
//       ) {
//         words_string += "Thousand ";
//       }
//       if (
//         i === 6 &&
//         value !== 0 &&
//         n_array[i + 1] !== 0 &&
//         n_array[i + 2] !== 0
//       ) {
//         words_string += "Hundred and ";
//       } else if (i === 6 && value !== 0) {
//         words_string += "Hundred ";
//       }
//     }
//     words_string = words_string.split("  ").join(" ");
//   }
//   return words_string;
// }
var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function toWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        ""
      : "";
  return str;
}
//Capitalize first letter
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//Check if the input field is empty or not
export const isRequired = (fieldName) => {
  let regx = /\S/;

  let isValid = true;
  if (!regx.test(fieldName)) {
    isValid = false;
  }
  return isValid;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//Format currency
var currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  KPW: "₩", //Korea (North) Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
  TRY: "₺", //Turkish New lira
  MYR: "RM", //malaysia

  //get currency based on country code
  MY: "RM", // malaysia
  SG: "S$", // singapor
  IDR: "IDR", //Indonesia
  AED: "AED", //United Arab Emirates dirham
  AFN: "AFN", //Afghanistan Afghani
  ANG: "ANG", //Netherlands Antilles Guilder
  ARS: "ARS", //Argentina Peso
  AUD: "AUD", //	Australia Dollar
  BDT: "BDT", //Bangladesh Taka
  BRL: "BRL", //Brazil Real
  BSD: "BSD", //Bahamas Dollar
  CAD: "CAD", //Canada Dollar
  CHF: "CHF", //Switzerland Franc
  CLP: "CLP", //Chile Peso
  CNY: "CNY", //China Yuan Renminbi
  COP: "COP", //Colombia Peso
  DKK: "DKK", //Denmark Krone
  EGP: "EGP", //Egypt Pound
  HKD: "HKD", //Hong Kong Dollar
  ISK: "ISK", //	Iceland Krona
  JEP: "JEP", //Jersey Pound
  JMD: "JMD", //Jamaica Dollar
  JOD: "JOD", //Jordan Dinar
  KHR: "KHR", //Cambodia Riel
  KWD: "KWD", //Kuwait Dinar
  LKR: "LKR", //Sri Lanka Rupee
  MAD: "MAD", //Morocco Dirham
  LYD: "LYD", //Libya Dinar
  MXN: "MXN", //Mexico Peso
  NOK: "NOK", //Norway Krone
  NAD: "NAD", //Namibia Dollar
  NZD: "$", //New Zealand Dollar
  PKR: "PKR", //Pakistan Rupee
  QAR: "QAR", //Qatar Riyal
  RON: "RON", //Romania Leu
  RSD: "RSD", //Serbia Dinar
  RUB: "RUB", //	Russia Ruble
  RWF: "RWF", //Rwanda Franc
  SAR: "SAR", //Saudi Arabia Riyal
  SEK: "SEK", //Sweden Krona
  SGD: "s$", //Singapore Dollar
  ZAR: "ZAR", //South Africa Rand
  BGN: "лв", //Bulgarian lev
  CZK: "Kč", //Czech Koruna
};
export const formatCurrency = (currency, price) => {
  let currency_name = "INR";

  //console.log("price", price);
  //console.log("currency", currency, price);
  let decimalPrice = parseFloat(price).toFixed(2);
  currency_name = currency?.currency;
  let format;
  if (currency_symbols[currency_name] !== undefined) {
    format = `${currency_symbols[currency_name]} ${decimalPrice}`;
  } else {
    format = `${decimalPrice}`;
  }

  return format;
};

export const formatPriceWithCurrency = (currency, price) => {
  //console.log("price", price);
  let currency_name = currency || "INR";

  //console.log("price", price);
  //console.log("currency", currency, price);
  let decimalPrice = numberWithCommas(parseFloat(price).toFixed(2));
  let format;
  if (currency_symbols[currency_name] !== undefined) {
    format = `${currency_symbols[currency_name]} ${decimalPrice}`;
  } else {
    format = `${decimalPrice}`;
  }

  return format;
};
// //For Customer flow currency
// export const formatCustomerFlowCurrency = (currency, price) => {
//   let currency_name = "INR";

//   //console.log("currency", currency, price);
//   let decimalPrice = parseFloat(price).toFixed(2);

//   if (currency.presentment_currency) {
//     currency_name = currency.presentment_currency;
//   } else {
//     currency_name = currency.currency;
//   }

//   let format;
//   if (currency_symbols[currency_name] !== undefined) {
//     format = `${currency_symbols[currency_name]} ${decimalPrice}`;
//   } else {
//     format = `${decimalPrice}`;
//   }

//   return format;
// };
//For Customer flow currency
export const formatCustomerFlowCurrency = (currency, price) => {
  let currency_name = currency || "INR";

  //console.log("currency", currency, price);
  let decimalPrice = parseFloat(price).toFixed(2);

  let format;
  if (currency_symbols[currency_name] !== undefined) {
    format = `${currency_symbols[currency_name]} ${decimalPrice}`;
  } else {
    format = `${decimalPrice}`;
  }

  return format;
};

export const getCurrencyCode = (currency) => {
  return currency_symbols[currency.currency];
};

export const getOnlyCurrencyCode = (currency_code) => {
  return currency_symbols[currency_code];
};

export const calculatePrice = (price) => {
  let { actual_amount, total_tax, return_quantity, total_discount } = price;
  let updatedPrice = "";
  updatedPrice = actual_amount + total_tax - total_discount;
  // console.log("updatedPrice", updatedPrice);
  return updatedPrice;
};

export const calculateCappedPrice = (price) => {
  let { actual_amount, total_tax } = price;
  let updatedPrice = "";
  updatedPrice = actual_amount + total_tax;
  return updatedPrice;
};

export const calculateRefundAmount = (price, returnFee) => {
  let {
    actual_amount,
    total_tax,
    return_quantity,
    total_discount,
    shipping_amount,
  } = price;
  let updatedPrice = "";
  updatedPrice =
    (actual_amount + total_tax) * return_quantity -
    total_discount * return_quantity;
  if (returnFee > 0) {
    updatedPrice = updatedPrice - returnFee;
    //if price is negative, then make it 0
    if (Math.sign(updatedPrice) === -1) {
      updatedPrice = 0;
    }
  }
  return updatedPrice;
};

export const calculateTotalRefundAmount = (amount, shipping_amount) => {
  let totalAmount =
    parseFloat(amount) + parseFloat(shipping_amount ? shipping_amount : 0);
  return totalAmount.toString();
};

//Remove country code (+91) from phone number
export const removeCourntyCode = (phoneNumber) => {
  let mobile = "";

  //First remove all spaces:
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\s/g, "");
    // If there is a countrycode, this IF will remove it..
    if (phoneNumber.startsWith("+")) {
      var temp = phoneNumber.substring(3, phoneNumber.length);
      mobile = temp;
    }
    // If there is no countrycode, only remove spaces
    else {
      mobile = phoneNumber;
    }
    return mobile;
  } else {
    return phoneNumber;
  }
};

//To get camelCase
export const toCamelcase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

//First letter capital
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

//replace space (" ") with underscore (_)
export const stringlowercaseRemoveSpace = (s) => {
  if (typeof s !== "string") return "";
  return s.replaceAll(" ", "_").toLowerCase();
};

// Convert file to base64 string
export const fileToBase64 = (filename, filepath) => {
  return new Promise((resolve) => {
    var file = new File([filename], filepath);
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
// Example call:
// fileToBase64("test.pdf", "../files/test.pdf").then(result => {
//   console.log(result);
// });

export const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: "smooth",
  });

export const scrollToHash = (location, isStyle) => {
  const element = document.getElementById(location.hash.replace("#", ""));
  window.scrollTo({
    behavior: element ? "smooth" : "auto",
    top: element ? element.offsetTop - 200 : 0,
  });
  if (isStyle && element) {
    element.style.cssText = `
    outline: 3px solid #6371c7; padding:10px
  `;
  }
  function removeStyle(element) {
    element.style.outline = "";
    element.style.padding = "";
  }
  element &&
    element.addEventListener("click", () => {
      removeStyle(element);
    });
  setTimeout(() => {
    if (isStyle && element) {
      removeStyle(element);
    }
  }, 5000);
};

export const scrollToElement = (element, isStyle, isDanger) => {
  let offset = element.getBoundingClientRect();
  console.log({ offset });
  window.scrollTo({
    behavior: element ? "smooth" : "auto",
    top: element ? offset.top : 0,
  });

  let outlineColor = isDanger ? "#bf0711" : "#6371c7";
  if (isStyle && element) {
    element.style.cssText = `
    outline: 3px solid ${outlineColor}; padding:10px
  `;
  }

  function removeStyle(element) {
    element.style.outline = "";
    element.style.padding = "";
  }
  element.addEventListener("click", () => {
    removeStyle(element);
  });
  setTimeout(() => {
    if (isStyle && element) {
      removeStyle(element);
    }
  }, 5000);
};

//Remove script tag from html
export function stripScripts(s) {
  var div = document.createElement("div");
  div.innerHTML = s;
  var scripts = div.getElementsByTagName("script");
  var i = scripts.length;
  while (i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
}

//Open base64 in new tab inside a iframe
// export function debugBase64(base64URL, type) {
//     //let url = `data:${type};base64,${base64URL}`;
//     var win = window.open();
//   win.document.write(
//     '<iframe src="' +
//       base64URL +
//       '" title="test" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
//   );
//   }

export function debugBase64(base64Data, fileName = "return_label.pdf", type) {
  //let width = type === "application/pdf" ? "100%" : "auto";
  let pdfWindow = window.open("");
  pdfWindow.document.write(
    "<html<head><title>" +
      fileName +
      "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>"
  );
  pdfWindow.document.write(
    '<iframe src="' +
      base64Data +
      '" title="test" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );

  // pdfWindow.document.write(
  //   "<body><embed width='" +
  //     width +
  //     "' height='100%' src='" +
  //     encodeURI(base64Data) +
  //     "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>"
  // );
}

export const restrictTwoDecimal = (value) => {
  let t = value;
  value =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
      : t;
  return value;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const truncate = (str, truncateLength) => {
  return str.length > truncateLength
    ? str.substring(0, truncateLength) + "..."
    : str;
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}
// e.g ->
// const arr1 = getUniqueListBy(arr, 'line_item_id');

//Calculate minute difference between two date
export function diff_minutes(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export const removeString = (string) => {
  return string.replace(/\#/g, "");
};
export const getLastItem = (path) => {
  return path.substring(path.lastIndexOf("/") + 1);
};

export const isObject = (variable) => {
  return typeof variable === "object" && variable !== null;
};
export const isObjectEmpty = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }
  return false;
};

export const handleBrokenImageError = (e) => {
  //console.log("broken image");
  e.target.src = Placeholder;
};

// 234234  --> 23MB
export const getFileSize = (size) => {
  var _size = size;
  var fSExt = new Array("Bytes", "KB", "MB", "GB"),
    i = 0;
  while (_size > 900) {
    _size /= 1024;
    i++;
  }
  var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
  return exactSize;
};

//IMAGE/PNG  --> PNG
export const getFileExtension = (filename) => {
  return filename?.split("/").pop();
};

export const getFileExtensionFromUrl = (filename) => {
  return filename?.split(".").pop();
};

//Check if file type is image
///image/png
export function isFileImage(type) {
  return type?.split("/")[0] === "image";
}

//Find URLs in string and make a link using JavaScript
export function replaceURLs(message) {
  if (!message) return;

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      '<a href="' +
      hyperlink +
      '" target="_blank" rel="noopener noreferrer" class="comment__link">' +
      url +
      "</a>"
    );
  });
}

export function getKeyByValue(object, value) {
  for (var prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
}

//Sending message on Intercom on user's behalf
let sendInterval = null;
function sendMessage() {
  let iframee = document.getElementsByName("intercom-messenger-frame");
  //console.log(iframee);
  if (iframee && iframee.length > 0) {
    setTimeout(() => {
      iframee[0].contentDocument
        .querySelector(".intercom-composer-send-button")
        ?.click();
    }, 2500);
    clearInterval(sendInterval);
  }
}

export const showCustomMessage = (message) => {
  window.Intercom("showNewMessage", message);
  // let metadata = {
  //   invitee_email: "pi@example.org",
  //   invite_code: "ADDAFRIEND",
  // };
  // window.Intercom("trackEvent", "invited-friend", metadata);
  sendInterval = setInterval(sendMessage, 500);
};

//check if order is COD
export const checkOrderIsCode = (order) => {
  let isCodOrder = false;
  if (order != null) {
    let gateway_authorization = order.transactions.length
      ? order.transactions[order.transactions.length - 1].authorization
      : null;
    let order_receipt = order.transactions.length
      ? order.transactions[order.transactions.length - 1].receipt
      : {};
    let transactions_status = order.transactions.length
      ? order.transactions[order.transactions.length - 1].status
      : "success";
    let order_financial_status = order.financial_status;

    //if Payment gateway name contains cash then it's COD
    let payment_gateway_names = order?.payment_gateway_names;
    if (payment_gateway_names && payment_gateway_names.length > 0) {
      payment_gateway_names?.forEach((item, index) => {
        if (item.includes("cash")) {
          isCodOrder = true;
        }
      });
    }
    if (order_financial_status === "pending") {
      isCodOrder = true;
    } else if (transactions_status === "pending") {
      isCodOrder = true;
    } else if (gateway_authorization === null && isObjectEmpty(order_receipt)) {
      isCodOrder = true;
    }
  }

  return isCodOrder;
};

export const checkVariantIsAvalible = (variant, store_settings) => {
  if (variant.inventory_management !== null) {
    if (
      variant.inventory_policy === "deny" &&
      !store_settings.allow_oos_exchange
    ) {
      if (variant.inventory_quantity <= 0) {
        return false;
      }
    }
  }

  return true;
};

export function removeHttp(url) {
  return url.replace(/^https?:\/\//, "");
}

//E.g  -  getVariantImage("35375893789541", images);
export function getVariantImage(variantImageId, imageArray) {
  for (var i = 0; i < imageArray.length; i++) {
    if (imageArray[i].id === variantImageId) {
      return imageArray[i].src;
    }
  }
}

export const isMultiCurrencyOrder = (item) => {
  if (
    item.price_set.presentment_money.currency_code !==
    item.price_set.shop_money.currency_code
  ) {
    return true;
  }
  return false;
};

export const checkAllObjectKeysRreTruthy = (obj) => {
  const allTrue = Object.values(obj).every((value) => value);
  return allTrue;
};

//show unlock feature
export const showUnlockFeature = (unlock_feature, countryCode) => {
  let showFeature = true;
  if (countryCode == "IN") {
    const allTrue = checkAllObjectKeysRreTruthy(unlock_feature);
    if (allTrue) showFeature = false;
  }
  if (countryCode !== "IN") {
    let unlock_feature_copy = { ...unlock_feature };
    delete unlock_feature_copy.stepRazorpay;
    const allTrue = checkAllObjectKeysRreTruthy(unlock_feature_copy);
    if (allTrue) showFeature = false;
  }
  return showFeature;
};

export const calculatePercentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};

/*
arry => [{label:"Name", isChecked:true},{label:"Email", isChecked:true}]
keyName => checked => Based on which value you want to check
*/
export const checkAllArrayOfObjectsAreTruthy = (arry, keyName) => {
  const isAllChecked = (currentValue) => currentValue[keyName] === true;
  return arry.every(isAllChecked);
};

//remove specific Character from the Start and End of a String
export const removeSpecialCharFromStartAndEndString = (str) => {
  //return str.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, "");
  // return str.replace(
  //   /^[\s&\/\\#,+()$~%'":*?<>{}^@!_-]+|[\s&\/\\#,+()$~%'":*?<>{}^@!_-]+$/g,
  //   ""
  // );
  return str.replace(
    /^[\s&\/\\,+$~%'":*?<>^@!_-]+|[\s&\/\\,+$~%'":*?<>^@!_-]+$/g,
    ""
  );
};

export const removeSpaceFromString = (string) => {
  return string.replace(/\n/g, "");
};

export const randomTextId = (length = 10) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isObjectHasKeys = (obj) => {
  return Object.keys(obj).length > 0;
};
