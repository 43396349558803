import axios from "axios";

let baseURL = "https://admin.returnprime.com/";
//let baseURL = "https://devapi.returnprime.com/";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("RP_token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log("error cors", error);
//     if (typeof error?.response === "undefined") {
//       window.location.reload();
//     }

//     return Promise.reject(error);
//   }
// );

//Refresh token
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 302)
    ) {
      if (!isAlreadyFetchingAccessToken) {
        if (localStorage.getItem("RP_refresh_token")) {
          isAlreadyFetchingAccessToken = true;
          originalRequest._retry = true;
          try {
            const rs = await instance.post("/user/refresh-token", {
              refresh_token: localStorage.getItem("RP_refresh_token"),
              store: localStorage.getItem("RP_store_url"),
            });
            // console.log("refresh token data", rs);
            const { access_token, refresh_token } = rs.data;
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(access_token);
            localStorage.setItem("RP_token", access_token);
            localStorage.setItem("RP_refresh_token", refresh_token);
            return instance(originalRequest);
          } catch (_error) {
            localStorage.removeItem("RP_token");
            window.location.href = `/user/login?store=${localStorage.getItem(
              "RP_store_url"
            )}&session=expired`;
            return Promise.reject(_error);
          }
        } else {
          localStorage.removeItem("RP_token");
          window.location.href = `/user/login?store=${localStorage.getItem(
            "RP_store_url"
          )}&session=expired`;
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = access_token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default instance;
