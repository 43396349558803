import {
  SET_CURRENT_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING,
  STOP_LOADING,
  GET_DASHBOARD_DATA,
  SHOW_TOAST,
  DISMISS_TOAST,
  SET_STORE_LOGO,
  UPDATE_STORE_SETUP,
  REMOVE_KEEP_SHOWING_DASHBOARD,
  TOGGLE_HOTKEY_DIALOG,
} from "../actions/types";
import isEmpty from "../../Components/Utils/isEmpty";

export default (state, action) => {
  //console.log(action.type);
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_STORE_LOGO:
      return {
        ...state,
        store_logo: action.payload,
      };

    case GET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      };

    case UPDATE_STORE_SETUP:
      return {
        ...state,
        dashboard: action.payload,
        KeepShowingDashboardSteps: true,
      };

    case REMOVE_KEEP_SHOWING_DASHBOARD: {
      return {
        ...state,
        KeepShowingDashboardSteps: false,
      };
    }
    case TOGGLE_HOTKEY_DIALOG: {
      return {
        ...state,
        showHotKeyDialog: !state.showHotKeyDialog,
      };
    }
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case DISMISS_TOAST:
      return {
        ...state,
        showToast: false,
        toastError: false,
        toastMsg: "",
      };
    case SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        toastError: action.payload.toastError,
        toastMsg: action.payload.toastMsg,
        loading: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
