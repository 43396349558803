import React from "react";
import MobileImg from "../../assets/mobile.png";
import "./spinner.scss";

const MobileRestrict = () => {
  return (
    <div className="mobile__restrict">
      <div className="mobile__restrict-wrapper">
        <img src={MobileImg} alt="mobile" />
        <h2>Please Switch Your Device</h2>
        <p>
          This page cannot opened in mobile. Please <br /> switch to Laptop /
          Desktop to <br /> view this page
        </p>
      </div>
    </div>
  );
};

export default MobileRestrict;
