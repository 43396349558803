export const timeoutMessage =
  "It seems like your request is taking too much time for response. Request Timeout. Please try again.";

// export const timeoutMessage =
//   "Looks like something is not right at the moment. Please try again and let us know on live chat if the problem persists.";

//UPS service type list option
export const upsServiceTypeOptions = [
  { label: "Next Day Air ", value: "01" },
  { label: "2nd Day Air", value: "02" },
  { label: "Ground ", value: "03" },
  { label: "Express", value: "07" },
  { label: "Expedited", value: "08" },
  { label: "UPS Standard", value: "11" },
  { label: "3 Day Select", value: "12" },
  { label: "Next Day Air Saver", value: "13" },
  { label: " UPS Next Day Air® Early", value: "14" },
  { label: "UPS Worldwide Economy DDU ", value: "17" },
  { label: "Express Plus", value: "54" },
  { label: "2nd Day Air A.M.", value: "59" },
  { label: "UPS Saver", value: "65" },
  { label: "First Class Mail", value: "M2" },
  { label: "Priority Mail", value: "M3" },
  { label: "Expedited MaiI Innovations", value: "M4" },
  { label: "Priority Mail Innovations", value: "M5" },
  { label: "Economy Mail Innovations", value: "M6" },
  { label: "MaiI Innovations (MI) Returns", value: "M7" },
  { label: "UPS Access PointTM Economy", value: "70" },
  { label: "UPS Worldwide Express Freight Midday", value: "71" },
  { label: "UPS Worldwide Economy", value: "72" },
  { label: "UPS Express®12:00", value: "74" },
  { label: "UPS Today Standard", value: "82" },
  { label: "UPS Today Dedicated Courier", value: "83" },
  { label: "UPS Today Intercity", value: "84" },
  { label: "UPS Today Express", value: "85" },
  { label: "UPS Today Express Saver", value: "86" },
  { label: "UPS Worldwide Express Freight.", value: "96" },
];

//DHL for Domestic Returns
export const countryListForDhl = [
  { label: "Austria", value: "aut" },
  { label: "Belgium", value: "bel" },
  { label: "Bulgaria", value: "bgr" },
  { label: "Croatia", value: "hrv" },
  { label: "Cyprus", value: "cyp" },
  { label: "Czechia", value: "cze" },
  { label: "Denmark", value: "dnk" },
  { label: "Estonia", value: "est" },
  { label: "Finland", value: "fin" },
  { label: "France", value: "fra" },
  { label: "Germany", value: "deu" },
  { label: "Greece", value: "grc" },
  { label: "Hungary", value: "hun" },
  { label: "Ireland", value: "irl" },
  { label: "Latvia", value: "lva" },
  { label: "Lithuania", value: "ltu" },
  { label: "Luxembourg", value: "lux" },
  { label: "Malta", value: "mlt" },
  { label: "Netherlands", value: "nld" },
  { label: "Poland", value: "pol" },
  { label: "Portugal", value: "prt" },
  { label: "Romania", value: "rou" },
  { label: "Slovakia", value: "svk" },
  { label: "Slovenia", value: "svn" },
  { label: "Spain", value: "esp" },
  { label: "Sweden", value: "swe" },
  { label: "Switzerland", value: "che" },
  { label: "United Kingdom of Great Britain", value: "gbr" },
];

export const lenscart_test = "lenskartae.myshopify.com";
export const lenscart = "lenskart-international.myshopify.com";
//export const lenscart = "returnprime1.myshopify.com";

// Logistics section save & activate button name
export const returnButtonName = (name) => {
  switch (name) {
    case "shipstation":
      return "Verify and connect";
      break;
    case "usps":
      return "Request activation";
      break;
    default:
      return "Save and activate";
  }
};

export const videos = {
  shiprocket: "g6Jxv-kwdLE",
  shadowfax: "WwaYbA1twJQ",
  delhivery: "JznYqHzBpSU",
  bluedart: "2-yxc2BX2RM",
  pickrr: "0u-om7R2BP4",
  nimbus: "4X4lJ28JIYw",
  shippo: "ZH5eO4pKPaQ",
  shipstation: "1GnG24YtSDo",
  ithink: "USAzCeHKJlo",
  canadapost: "scpYnclpKPU",
  shyplite: "cJ9MAMFuFmo",
  "dpd-uk": "TSnzT-Tr7dw",
  usps: "KkBfJRk3q-U",
  ups: "kNm_XkBHQvo",
  easyparcel: "pUvAe6S3MBY",
  razorpay: "F_nYovNI0rU",
};

export const ListShortcutKeysConstants = {
  ArrowUp: "ArrowUp",
  ArrowDown: "ArrowDown",
  Enter: "Enter",
};
