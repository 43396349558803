import React, { createContext } from "react";
import AuthReducer from "../reducers/authReducer";

// Initial state
const initialState = {
  isAuthenticated: false,
  user: {},
  store_logo: null,
  loading: false,
  dashboard: null,
  showToast: false,
  toastMsg: "",
  toastError: false,
  showHotKeyDialog: false,
};

//Create Context
//The createContext method returns an object with a Provider and a Consumer component:
export const AuthContext = createContext(initialState);

const { Provider } = AuthContext;

//Provider component
//The Provider component is what makes the state available to all child components, no matter how deeply nested they are within the component hierarchy. The Provider component receives a value prop. This is where we’ll pass our current value:
export const AuthProvider = (props) => {
  //useReducer Hook receives two values as its argument — in this case, the current state and an action — and then returns a new state:
  const [state, dispatch] = React.useReducer(AuthReducer, initialState);
  const value = { state, dispatch };

  return (
    <Provider value={value} displayName="Auth">
      {props.children}
    </Provider>
  );
};
